<template>
  <control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :is-focused="isFocused"
    :appliedOptions="appliedOptions"
    :disabled="!control.enabled"
    :fieldData="control.data"
  >
    <select
      :id="control.id + '-input'"
      :class="styles.control.select"
      :value="control.data"
      :disabled="!control.enabled"
      :autofocus="appliedOptions.focus"
      @change="onChange"
      @focus="isFocused = true"
      @blur="isFocused = false"
    >
      <option key="empty" value="" :class="styles.control.option"></option>
      <option
        v-for="optionElement in oneOfSchema?.oneOf"
        :selected="oneOfSchema?.default === optionElement.const"
        :key="optionElement.const"
        :value="optionElement.const"
        :class="styles.control.option"
      >
        {{ optionElement.title }}
      </option>
    </select>
  </control-wrapper>
</template>

<script lang="ts">
import { ControlElement, JsonSchema, Resolve } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsOneOfControl, RendererProps } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import ControlWrapper from './ControlWrapper.vue';

const SelectRenderer = defineComponent({
  name: 'SelectRenderer',
  components: {
    ControlWrapper,
  },
  computed: {
    oneOfSchema(): JsonSchema | undefined {
      return Resolve.schema(this.schema, this.control.uischema.scope, this.control.rootSchema);
    },
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaControl(useJsonFormsOneOfControl(props), (target) =>
      target.selectedIndex === 0 ? undefined : target.value
    );
  },
});

export default SelectRenderer;
</script>
