<template>
  <div :class="styles.arrayList.item">
    <div class="drag-control" v-if="isDraggable">
      <div class="array-list-drag-control">
        <font-awesome-icon size="xl" icon="fa-regular fa-grip-dots-vertical" />
      </div>
    </div>

    <div :class="contentClasses">
      <slot></slot>
    </div>

    <div :class="toolbarClasses">
      <button
        :class="styles.arrayList.itemDelete"
        :disabled="!deleteEnabled"
        type="button"
        class="btn btn-small btn-danger"
        @click="deleteItem"
      >
        <font-awesome-icon icon="fa-regular fa-xmark" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { classes, Styles } from '@jsonforms/vue-vanilla';

const ArrayListElement = defineComponent({
  name: 'ArrayListElement',
  props: {
    isDraggable: {
      required: false,
      type: Boolean,
      default: false,
    },
    initiallyExpanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: [Function, String],
      default: undefined,
    },
    deleteEnabled: {
      required: false,
      type: Boolean,
      default: true,
    },
    delete: {
      required: false,
      type: Function,
      default: undefined,
    },
    styles: {
      required: true,
      type: Object as PropType<Styles>,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
    };
  },
  computed: {
    contentClasses(): string {
      return classes`${this.styles.arrayList.itemContent} ${
        this.expanded && this.styles.arrayList.itemExpanded
      }`;
    },
    toolbarClasses(): string {
      return classes`${this.styles.arrayList.itemToolbar} ${
        this.expanded && this.styles.arrayList.itemExpanded
      }`;
    },
  },
  methods: {
    toggleExpand(): void {
      this.expanded = !this.expanded;
    },
    deleteItem(event: Event): void {
      event.stopPropagation();
      this.delete?.();
    },
  },
});

export default ArrayListElement;
</script>
