import { createApp } from 'vue';
import App from './AppPreview.vue';
import router from './router';

import { icons } from '@/components/utilities/Icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@/assets/scss/main.scss';

const app = createApp(App);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
for (const icon of icons) {
  library.add(icon);
}
app.mount('#app');
