<template>
  <div v-if="label.visible" v-html="getHtml"></div>
</template>

<script lang="ts">
import { LabelElement } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsLabel, RendererProps } from '@jsonforms/vue';

import { marked } from 'marked';
import { useVanillaLabel } from '@jsonforms/vue-vanilla';

const MarkdownRenderer = defineComponent({
  name: 'markdownRenderer',
  props: {
    ...rendererProps<LabelElement>(),
  },
  setup(props: RendererProps<LabelElement>) {
    return useVanillaLabel(useJsonFormsLabel(props));
  },
  computed: {
    getHtml() {
      let markdown = '';
      if (this.label.text) {
        markdown = this.label.text;
      }

      return marked(markdown);
    },
  },
});

export default MarkdownRenderer;
</script>
