<template>
  <div v-if="control.visible" :class="[styles.arrayList.root, { 'has-drag': isDraggable }]">
    <header class="array-list-header">
      <template v-for="item in control.schema.properties" :key="item.description">
        <div class="array-list-th">
          {{ item.title }}
          <DescriptionWrapper :description="item.description" />
        </div>
      </template>
    </header>

    <div class="array-list-empty" v-if="(control.data || []).length === 0">
      <span v-for="item in control.schema.properties" :key="item.description">&nbsp;</span>
      <span>&nbsp;</span>
    </div>

    <draggable
      v-if="isDraggable"
      :component-data="componentData"
      :list="control.data"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="index"
      :handle="'.drag-control'"
      :class="styles.arrayList.itemWrapper"
    >
      <template #item="{ index }">
        <array-list-element
          :delete-enabled="!minItemsReached"
          :delete="removeItems!(control.path, [index])"
          :label="childLabelForIndex(index)"
          :styles="styles"
          :key="`${control.path}-${index}`"
          :is-draggable="isDraggable"
        >
          <dispatch-renderer
            :schema="control.schema"
            :uischema="childUiSchema"
            :path="composePaths(control.path, `${index}`)"
            :enabled="control.enabled"
            :renderers="control.renderers"
            :cells="control.cells"
          >
          </dispatch-renderer>
        </array-list-element>
      </template>
    </draggable>

    <div
      v-else
      v-for="(element, index) in control.data"
      :key="`${control.path}-${index}`"
      :class="styles.arrayList.itemWrapper"
    >
      <array-list-element
        :delete-enabled="!minItemsReached"
        :delete="removeItems!(control.path, [index])"
        :label="childLabelForIndex(index)"
        :styles="styles"
        :draggable="false"
      >
        <dispatch-renderer
          :schema="control.schema"
          :uischema="childUiSchema"
          :path="composePaths(control.path, `${index}`)"
          :enabled="control.enabled"
          :renderers="control.renderers"
          :cells="control.cells"
        />
      </array-list-element>
    </div>

    <footer>
      <div class="align-end">
        <div class="wrap">
          <add-button
            :class="styles.arrayList.addButton"
            type="button"
            class="btn btn-small"
            @click="addRow"
            :disabled="!control.enabled || maxItemsReached"
          >
            Click + to add
          </add-button>
        </div>
      </div>
    </footer>

    <div v-if="noData" :class="styles.arrayList.noData"></div>
  </div>
</template>

<script lang="ts">
import { composePaths, createDefaultValue, ControlElement, Resolve } from '@jsonforms/core';
import { defineComponent } from 'vue';
import draggable from 'vuedraggable';
import { type JsonSchema } from '@jsonforms/core';
import {
  rendererProps,
  useJsonFormsArrayControl,
  RendererProps,
  DispatchRenderer,
} from '@jsonforms/vue';
import { useVanillaArrayControl } from '@jsonforms/vue-vanilla';
import ArrayListElement from './ArrayListElementRenderer.vue';
import DescriptionWrapper from '@/components/forms/DescriptionWrapper.vue';
import AddButton from './common/AddButton.vue';

type JsonSchemaWithOptions = JsonSchema & {
  options?: {
    sortable: boolean;
  };
};

interface DragOptions {
  animation: number;
  disabled: boolean;
  ghostClass: string;
}

interface ComponentData {
  drag: boolean;
  showDescription: boolean;
  componentData: {
    modelValue: string[];
    tag: string;
    type: string;
    name: string | null;
  };
}

const ArrayListRenderer = defineComponent({
  name: 'ArrayListRenderer',
  provide() {
    return {
      showLabel: false,
    };
  },
  components: {
    'array-list-element': ArrayListElement,
    DispatchRenderer,
    DescriptionWrapper,
    draggable,
    AddButton,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaArrayControl(useJsonFormsArrayControl(props));
  },
  computed: {
    isDraggable(): boolean {
      if (this.arraySchema && 'options' in this.arraySchema) {
        return this.arraySchema.options?.sortable || false;
      }

      return false;
    },
    dragOptions(): DragOptions {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    noData(): boolean {
      return !this.control.data || this.control.data.length === 0;
    },
    arraySchema(): JsonSchemaWithOptions {
      return Resolve.schema(this.schema, this.control.uischema.scope, this.control.rootSchema);
    },
    maxItemsReached(): boolean | undefined {
      return (
        this.arraySchema !== undefined &&
        this.arraySchema.maxItems !== undefined &&
        this.control.data !== undefined &&
        this.control.data.length >= this.arraySchema.maxItems
      );
    },
    minItemsReached(): boolean | undefined {
      return (
        this.arraySchema !== undefined &&
        this.arraySchema.minItems !== undefined &&
        this.control.data !== undefined &&
        this.control.data.length <= this.arraySchema.minItems
      );
    },
  },
  methods: {
    composePaths,
    createDefaultValue,
    addRow() {
      this.addItem(this.control.path, createDefaultValue(this.control.schema))();
    },
  },
  data(): ComponentData {
    return {
      drag: false,
      showDescription: false,
      componentData: {
        modelValue: [],
        tag: 'div',
        type: 'transition-group',
        name: !this.drag ? 'flip-list' : null,
      },
    };
  },
});

export default ArrayListRenderer;
</script>
