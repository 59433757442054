<template>
  <div class="container">
    <json-forms-wrapper
      v-if="schema !== '' && uischema !== ''"
      :data="data"
      :schema="schema"
      :uischema="uischema"
      @change="onChange"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import JsonFormsWrapper from '@/components/forms/JsonFormsWrapper.vue';

export default defineComponent({
  name: 'app',
  components: {
    JsonFormsWrapper,
  },
  data() {
    return {
      data: {},
      schema: undefined as any,
      uischema: undefined as any,
    };
  },
  methods: {
    onChange(event: any) {
      this.data = event.data;
      if (this.data && Object.keys(this.data).length > 0) {
        let dataMsg = JSON.stringify(this.data);
        window.parent.postMessage(`DATA:${dataMsg}`, '*');
      }
    },
    determineMessage(message: string): MsgType {
      try {
        if (message) {
          if (message.startsWith('SCHEMA:')) {
            return MsgType.Schema;
          } else if (message.startsWith('UI_SCHEMA:')) {
            return MsgType.UiSchema;
          } else if (message.startsWith('DATA')) {
            return MsgType.Data;
          }
        }
      } catch (e) {
        console.log(e);
      }

      return MsgType.Unknown;
    },
    decodeSchema(message: string): string {
      const encoded = message.substring(7);
      return atob(encoded);
    },
    decodeUiSchema(message: string): string {
      const encoded = message.substring(10);
      return atob(encoded);
    },
  },
  mounted() {
    window.addEventListener('message', (event) => {
      const msgType = this.determineMessage(event.data);

      switch (msgType) {
        case MsgType.Schema:
          this.schema = JSON.parse(this.decodeSchema(event.data));
          break;
        case MsgType.UiSchema:
          this.uischema = JSON.parse(this.decodeUiSchema(event.data));
          break;
        case MsgType.Data:
          this.data = JSON.parse(event.data.substring(5));
          break;
        default:
          break;
      }
    });

    window.parent.postMessage('READY', '*');
  },
});

enum MsgType {
  Schema,
  UiSchema,
  Data,
  Unknown,
}
</script>
