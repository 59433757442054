// import regular icons
import {
  faBoxCheck as rBoxCheck,
  faCheck as rCheck,
  faFloppyDisk as rFloppyDisk,
  faFilePen as rFilePen,
  faGear as rGear,
  faGripDotsVertical as rGripDotsVertical,
  faHome as rHome,
  faKey as rKey,
  faRectangleList as rRectangleList,
  faRightFromBracket as rRightFromBracket,
  faUserSecret as rUserSecret,
  faXmark as rXmark,
  faTruckRampBox as rTruckRampBox,
  faCalendar as rCalendar,
  faPen as rPen,
  faUser as rUser,
  faUserGear as rUserGear,
  faArrowRight as rArrowRight,
  faRocket as rRocket,
} from '@fortawesome/pro-regular-svg-icons';

// import solid icons
import {
  faCaretRight as sCaretRight,
  faCaretDown as sCaretDown,
  faCheckDouble as sCheckDouble,
  faChevronRight as sChevronRight,
  faChevronLeft as sChevronLeft,
  faChevronDown as sChevronDown,
  faCircleCheck as sCircleCheck,
  faCircleChevronRight as sCircleChevronRight,
  faCircleExclamation as sCircleExclamation,
  faCircleXmark as sCircleXmark,
  faFilePen as sFilePen,
  faKey as sKey,
  faLoader as sLoader,
  faLocationDot as sLocationDot,
  faPlug as sPlug,
  faPlus as sPlus,
  faPlusLarge as sPlusLarge,
  faRotateRight as sRotateRight,
  faTimes as sTimes,
  faTrashXmark as sTrashXmark,
  faTriangleExclamation as sTriangleExclamation,
  faTruck as sTruck,
  faUserSecret as sUserSecret,
  faEye as sEye,
  faEyeSlash as eEyeSlash,
  faUnlockKeyhole as sUnlockKeyhole,
  faSquareCheck as sSquareCheck,
  faDownload as sDownload,
} from '@fortawesome/pro-solid-svg-icons';

export const icons = [
  // regular icons
  rBoxCheck,
  rGear,
  rGripDotsVertical,
  rHome,
  rKey,
  rUserSecret,
  rFloppyDisk,
  rRectangleList,
  rRightFromBracket,
  rXmark,
  rCheck,
  rFilePen,
  rTruckRampBox,
  rCalendar,
  rPen,
  rRocket,
  rUser,
  rUserGear,
  rArrowRight,
  // solid icons
  sCaretRight,
  sCaretDown,
  sKey,
  sUserSecret,
  sLoader,
  sLocationDot,
  sTimes,
  sTriangleExclamation,
  sPlus,
  sPlusLarge,
  sPlug,
  sCircleExclamation,
  sRotateRight,
  sCircleCheck,
  sCircleXmark,
  sChevronRight,
  sChevronLeft,
  sChevronDown,
  sChevronLeft,
  sCircleChevronRight,
  sCheckDouble,
  sFilePen,
  sTrashXmark,
  sEye,
  eEyeSlash,
  sUnlockKeyhole,
  sSquareCheck,
  sTruck,
  sDownload,
];
