import ArrayListRenderer from './ArrayListRenderer.vue';
import ArrayListBasicRenderer from './ArrayListBasicRenderer.vue';
import CheckboxRenderer from './CheckboxRenderer.vue';
import InputTextFieldRenderer from './InputTextFieldRenderer.vue';
import InputIntegerRenderer from './InputIntegerRenderer.vue';
import SelectRenderer from './SelectRenderer.vue';
import TextareaRenderer from './TextareaRenderer.vue';
import StringTokenRenderer from './StringTokenRenderer.vue';
import MarkdownRenderer from './MarkdownRenderer.vue';
import MultiSelectRenderer from './MultiSelectRenderer.vue';
import InputNumberRenderer from './InputNumberRenderer.vue';
import PasswordFieldRenderer from './PasswordFieldRenderer.vue';

import {
  rankWith,
  isBooleanControl,
  type JsonFormsRendererRegistryEntry,
  and,
  or,
  isStringControl,
  isNumberControl,
  isMultiLineControl,
  optionIs,
  scopeEndsWith,
  uiTypeIs,
  formatIs,
  isOneOfControl,
  isIntegerControl,
  isAnyOfControl,
  schemaTypeIs,
  isEnumControl,
} from '@jsonforms/core';

export const ArrayList: JsonFormsRendererRegistryEntry = {
  renderer: ArrayListRenderer,
  tester: rankWith(4, and(optionIs('renderWith', 'arrayList'))),
};

export const ArrayListBasic: JsonFormsRendererRegistryEntry = {
  renderer: ArrayListBasicRenderer,
  tester: rankWith(3, and(schemaTypeIs('array'))),
};

export const CheckboxField: JsonFormsRendererRegistryEntry = {
  renderer: CheckboxRenderer,
  tester: rankWith(2, and(isBooleanControl)),
};

export const InputTextField: JsonFormsRendererRegistryEntry = {
  renderer: InputTextFieldRenderer,
  tester: rankWith(2, and(isStringControl)),
};

export const IntegerField: JsonFormsRendererRegistryEntry = {
  renderer: InputIntegerRenderer,
  tester: rankWith(4, and(isIntegerControl)),
};

export const Markdown: JsonFormsRendererRegistryEntry = {
  renderer: MarkdownRenderer,
  tester: rankWith(1, uiTypeIs('Markdown')),
};

export const MultiSelect: JsonFormsRendererRegistryEntry = {
  renderer: MultiSelectRenderer,
  tester: rankWith(4, and(schemaTypeIs('array'), optionIs('renderWith', 'multiSelect'))),
};

export const NumberField: JsonFormsRendererRegistryEntry = {
  renderer: InputNumberRenderer,
  tester: rankWith(4, and(isNumberControl)),
};

export const PasswordField: JsonFormsRendererRegistryEntry = {
  renderer: PasswordFieldRenderer,
  tester: rankWith(3, and(isStringControl, formatIs('secret'))),
};

export const SelectItem: JsonFormsRendererRegistryEntry = {
  renderer: SelectRenderer,
  tester: rankWith(4, and(isOneOfControl)),
};

export const StringToken: JsonFormsRendererRegistryEntry = {
  renderer: StringTokenRenderer,
  tester: rankWith(3, and(isStringControl, scopeEndsWith('Token'))),
};

export const Textarea: JsonFormsRendererRegistryEntry = {
  renderer: TextareaRenderer,
  tester: rankWith(3, and(isStringControl, isMultiLineControl)),
};
