<template>
  <div v-if="control.visible" :class="[styles.arrayList.root]">
    <div
      v-for="(element, index) in control.data"
      :key="`${control.path}-${index}`"
      :class="styles.arrayList.itemWrapper"
    >
      <array-list-element
        :delete-enabled="!control.enabled || maxItemsReached"
        :delete="removeItems!(control.path, [index])"
        :label="childLabelForIndex(index)"
        :styles="styles"
      >
        <dispatch-renderer
          :schema="control.schema"
          :uischema="childUiSchema"
          :path="composePaths(control.path, `${index}`)"
          :enabled="control.enabled"
          :renderers="control.renderers"
          :cells="control.cells"
        />
      </array-list-element>
    </div>

    <footer>
      <div class="align-end">
        <div class="wrap">
          <span>Click + to add</span>
          <button
            :class="styles.arrayList.addButton"
            type="button"
            class="btn btn-small"
            @click="addRow"
            :disabled="!control.enabled || maxItemsReached"
          >
            +
          </button>
        </div>
      </div>
    </footer>

    <div v-if="noData" :class="styles.arrayList.noData"></div>
  </div>
</template>

<script lang="ts">
import { composePaths, createDefaultValue, ControlElement, Resolve } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { type JsonSchema } from '@jsonforms/core';
import {
  rendererProps,
  useJsonFormsArrayControl,
  RendererProps,
  DispatchRenderer,
} from '@jsonforms/vue';
import { useVanillaArrayControl } from '@jsonforms/vue-vanilla';
import ArrayListElement from './ArrayListElementRenderer.vue';

type JsonSchemaWithOptions = JsonSchema & {
  options?: {
    sortable: boolean;
  };
};

interface ComponentData {
  showDescription: boolean;
  componentData: {
    modelValue: string[];
    tag: string;
    type: string;
    name: string | null;
  };
}

const ArrayListRenderer = defineComponent({
  name: 'ArrayListBasicRenderer',
  components: {
    'array-list-element': ArrayListElement,
    DispatchRenderer,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaArrayControl(useJsonFormsArrayControl(props));
  },
  computed: {
    isDraggable(): boolean {
      if (this.arraySchema && 'options' in this.arraySchema) {
        return this.arraySchema.options?.sortable || false;
      }

      return false;
    },
    noData(): boolean {
      return !this.control.data || this.control.data.length === 0;
    },
    arraySchema(): JsonSchemaWithOptions {
      return Resolve.schema(this.schema, this.control.uischema.scope, this.control.rootSchema);
    },
    maxItemsReached(): boolean | undefined {
      return (
        this.arraySchema !== undefined &&
        this.arraySchema.maxItems !== undefined &&
        this.control.data !== undefined &&
        this.control.data.length >= this.arraySchema.maxItems
      );
    },
    minItemsReached(): boolean | undefined {
      return (
        this.arraySchema !== undefined &&
        this.arraySchema.minItems !== undefined &&
        this.control.data !== undefined &&
        this.control.data.length <= this.arraySchema.minItems
      );
    },
  },
  methods: {
    composePaths,
    createDefaultValue,
    addRow() {
      this.addItem(this.control.path, createDefaultValue(this.control.schema))();
    },
  },
  data(): ComponentData {
    return {
      showDescription: false,
      componentData: {
        modelValue: [],
        tag: 'div',
        type: 'transition-group',
        name: !this.drag ? 'flip-list' : null,
      },
    };
  },
});

export default ArrayListRenderer;
</script>
