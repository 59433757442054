<!-- This renderer creates a input field, (as a content editable div) to add replaceble tokens. -->
<template>
  <!-- wrapper for title, discription etc. -->
  <control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :isFocused="isFocused"
    :appliedOptions="appliedOptions"
    :fieldData="control.data"
  >
    <div
      @focus="isFocused = true"
      @blur="isFocused = false"
      :id="control.id + '-template'"
      :disabled="!control.enabled"
      class="form-control contenteditable"
      contenteditable="true"
      @focusout="handleInput"
      v-html="showData"
    ></div>

    <!-- droptdown that shows selectable key-label values  -->
    <div
      v-if="schem.examples"
      class="dropdown-menu"
      :class="{ active: open }"
      v-click-outside="close"
    >
      <button
        class="btn btn-light dropdown-toggle"
        type="button"
        :id="control.id + '-button'"
        aria-expanded="false"
        @click="toggleDropdown"
      >
        Select dynamic field
      </button>

      <div class="dropdown-items" :aria-labelledby="control.id + '-button'">
        <div>
          <button
            class="dropdown-item"
            v-for="item in schem.examples"
            :key="item.key"
            @click="selectItem(item.key)"
            type="button"
          >
            {{ item.label }} - {{ item.key }}
          </button>
        </div>
      </div>
    </div>
  </control-wrapper>
</template>

<script lang="ts">
import { ControlElement, JsonSchema7 } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import ControlWrapper from './ControlWrapper.vue';

const StringTokenRenderer = defineComponent({
  name: 'StringTokenRenderer',
  props: {
    ...rendererProps<ControlElement>(),
  },
  components: {
    ControlWrapper,
  },
  data() {
    return {
      open: false,
      content: '',
    };
  },
  computed: {
    // allows access to jsonschema 7
    schem() {
      return this.control.schema as JsonSchema7;
    },
    showData() {
      if (this.control.data) {
        return this.control.data;
      } else {
        return this.content;
      }
    },
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaControl(useJsonFormsControl(props), (target) => target.value || undefined);
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
    },
    close() {
      this.open = false;
    },
    handleInput(event: any) {
      this.content = event.target.innerText;

      this.handleChange(this.control.path, this.content);
    },
    selectItem(item: string) {
      if (this.control?.data) {
        this.content = this.control.data;
      }

      this.content += ` [${item}]`;
      this.handleChange(this.control.path, this.content);
    },
  },
});
export default StringTokenRenderer;
</script>
