<template>
  <inline-control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :isFocused="isFocused"
    :appliedOptions="appliedOptions"
  >
    <input
      :id="control.id + '-input'"
      :value="control.data"
      :checked="control.data"
      @checked="control.data"
      :disabled="!control.enabled"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @change="onChange"
      type="checkbox"
    />
    <!--       
    <label :for="control.id + '-checkbox'" class="switch rounded">
      <input 
        :id="control.id + '-checkbox'" 
        :value="control.data" 
        @checked="control.data" 
        @disabled="!control.enabled"
        @focus="isFocused = true" 
        @blur="isFocused = false" 
        @change="onChange" 
        type="checkbox"
      />
      <span class="slider">&nbsp;</span>
    </label> -->
  </inline-control-wrapper>
</template>

<script lang="ts">
import { ControlElement } from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent } from 'vue';
import InlineControlWrapper from './InlineControlWrapper.vue';

const CheckboxRenderer = defineComponent({
  name: 'CheckboxRenderer',
  props: {
    ...rendererProps<ControlElement>(),
  },
  components: {
    InlineControlWrapper,
  },
  emits: ['input'],
  setup(props: RendererProps<ControlElement>) {
    return useVanillaControl(useJsonFormsControl(props), (target) => target.checked);
  },
  methods: {
    onChange(event: Event) {
      this.handleChange(this.control.path, (event.target as HTMLInputElement).checked);
    },
  },
});
export default CheckboxRenderer;
</script>
