<template>
  <span v-click-outside="close">
    <div class="description-wrapper">
      <button
        @click.prevent="toggleDescription()"
        v-if="description"
        class="btn description-toggle"
        :class="{ active: showDescription }"
        tabindex="-1"
      >
        ?
      </button>

      <div class="description" v-if="showDescription">
        {{ description }}
      </div>
    </div>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const showDescription = ref<boolean>(false);

defineProps<{
  description: string | undefined;
}>();

const toggleDescription = () => {
  showDescription.value = !showDescription.value;
};

const close = () => {
  showDescription.value = false;
};
</script>
