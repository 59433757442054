<template>
  <json-forms
    :data="data"
    :schema="schema"
    :uischema="uischema"
    :renderers="renderers"
    @change="onChange"
    :config="configs"
    :validationMode="validationMode"
    :readonly="readonly"
    :ajv="ajv"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { MaybeReadonly, JsonForms } from '@jsonforms/vue';
import { JsonFormsRenderers, JsonFormsStyles } from '@/services/json-forms-provides';
import {
  JsonSchema,
  UISchemaElement,
  JsonFormsCellRendererRegistryEntry,
  JsonFormsUISchemaRegistryEntry,
  ValidationMode,
  JsonFormsI18nState,
} from '@jsonforms/core';

import Ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import addFormats from 'ajv-formats';

const JsonFormsWrapper = defineComponent({
  name: 'JsonFormsWrapper',
  components: {
    JsonForms,
  },
  props: {
    data: {
      required: true,
      type: [String, Number, Boolean, Array, Object] as PropType<any>,
    },
    schema: {
      required: false,
      type: [Object, Boolean] as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: false,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
    cells: {
      required: false,
      type: Array as PropType<MaybeReadonly<JsonFormsCellRendererRegistryEntry[]>>,
      default: () => [],
    },
    config: {
      required: false,
      type: Object as PropType<any>,
      default: undefined,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    uischemas: {
      required: false,
      type: Array as PropType<MaybeReadonly<JsonFormsUISchemaRegistryEntry[]>>,
      default: () => [],
    },
    validationMode: {
      required: false,
      type: String as PropType<ValidationMode>,
      default: 'ValidateAndShow',
    },
    i18n: {
      required: false,
      type: Object as PropType<JsonFormsI18nState>,
      default: undefined,
    },
  },
  data() {
    return {
      renderers: Object.freeze(JsonFormsRenderers),
    };
  },
  provide() {
    return {
      styles: JsonFormsStyles,
    };
  },
  setup() {
    const ajv = new Ajv({
      allErrors: true,
      verbose: true,
      strict: false,
    });

    ajvErrors(ajv);
    addFormats(ajv);
    ajv.addFormat('secret', /^[^\s]+$/); // less strict, not allowing only whitespace

    return {
      ajv,
      configs: {
        restrict: true,
        trim: true,
        showUnfocusedDescription: true,
        hideRequiredAsterisk: false,
      },
    };
  },
  emits: ['change'],
  methods: {
    onChange(event: any) {
      this.$emit('change', event);
    },
  },
});

export default JsonFormsWrapper;
</script>
