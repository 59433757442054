<template>
  <control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :isFocused="isFocused"
    :appliedOptions="appliedOptions"
    :fieldData="control.data"
  >
    <div class="form-group relative-wrapper">
      <input
        :id="control.id"
        :class="styles.control.input"
        :value="control.data"
        :disabled="locked || !control.enabled"
        :autofocus="appliedOptions.focus"
        :placeholder="locked ? '••••••••' : ''"
        @change="onChange"
        @focus="isFocused = true"
        @blur="isFocused = false"
        :type="type"
      />
      <button v-if="locked" class="btn btn-none toggle-visibility" type="button" @click="unlock">
        <font-awesome-icon icon="fa-solid fa-unlock-keyhole" />
      </button>

      <button
        v-else-if="control.enabled"
        class="btn btn-none toggle-visibility"
        type="button"
        @click="toggle"
      >
        <font-awesome-icon v-if="!visible" icon="fa-solid fa-eye" />
        <font-awesome-icon v-if="visible" icon="fa-solid fa-eye-slash" />
      </button>
    </div>
  </control-wrapper>
</template>

<script lang="ts">
import { ControlElement } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import ControlWrapper from './ControlWrapper.vue';

const PasswordFieldRenderer = defineComponent({
  name: 'PasswordFieldRenderer',
  props: {
    ...rendererProps<ControlElement>(),
  },
  data() {
    return {
      visible: false,
      locked: false,
      type: 'password',
    };
  },
  components: {
    ControlWrapper,
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaControl(useJsonFormsControl(props), (target) => target.value || undefined);
  },
  created() {
    this.locked = !this.isEmpty(this.control.data);
  },
  methods: {
    isEmpty(input: string) {
      if (input) {
        return false;
      }
      return true;
    },
    onChange(event: Event) {
      this.handleChange(this.control.path, (event.target as HTMLInputElement).value);
    },
    unlock() {
      this.locked = false;
      this.handleChange(this.control.path, undefined);
    },
    toggle() {
      this.visible = !this.visible;
      if (this.visible) {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
  },
});

export default PasswordFieldRenderer;
</script>
