<template>
  <div class="container-fluid">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      userInformation: null,
    };
  },
};
</script>
