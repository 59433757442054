<template>
  <control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :isFocused="isFocused"
    :appliedOptions="appliedOptions"
    :fieldData="control.data"
  >
    <input
      :id="control.id + '-input'"
      :class="styles.control.input"
      :value="control.data"
      :disabled="!control.enabled"
      :autofocus="appliedOptions.focus"
      :placeholder="appliedOptions.placeholder"
      :required="appliedOptions.required"
      :minlength="appliedOptions.minLength"
      :maxlength="appliedOptions.maxLength"
      :step="step"
      @change="onChange"
      @focus="isFocused = true"
      @blur="isFocused = false"
      type="number"
    />
  </control-wrapper>
</template>

<script lang="ts">
import { ControlElement } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import ControlWrapper from './ControlWrapper.vue';

const InputNumberRenderer = defineComponent({
  name: 'InputNumberRenderer',
  props: {
    ...rendererProps<ControlElement>(),
  },
  components: {
    ControlWrapper,
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaControl(useJsonFormsControl(props), (target) =>
      target.value === '' ? undefined : Number(target.value)
    );
  },
  computed: {
    step(): number {
      return this.appliedOptions.step ?? 0.1;
    },
  },
});

export default InputNumberRenderer;
</script>
