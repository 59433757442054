<template>
  <control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :is-focused="isFocused"
    :appliedOptions="appliedOptions"
    :disabled="!control.enabled"
    :fieldData="control.data"
  >
    <VueMultiselect
      :id="control.id + '-input'"
      :options="getOptions?.items?.anyOf"
      v-model="selected"
      :multiple="true"
      :limit="multiSelectLimit"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :preselect-first="false"
      label="title"
      track-by="const"
    >
    </VueMultiselect>
  </control-wrapper>
</template>

<script lang="ts">
import VueMultiselect from 'vue-multiselect';
import { ControlElement, JsonSchema, Resolve } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsAnyOfControl, RendererProps } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import ControlWrapper from './ControlWrapper.vue';

// Extended type controlElement to enable options in the uiSchema
type ControlElementExtendedOptions = ControlElement['options'] & {
  'multiselect-options': {
    limit?: number;
  };
};

const MultiSelectRenderer = defineComponent({
  name: 'MultiSelectRenderer',
  components: {
    ControlWrapper,
    VueMultiselect,
  },
  computed: {
    getOptions(): JsonSchema | any | undefined {
      return Resolve.schema(this.schema, this.control.uischema.scope, this.control.rootSchema);
    },
    multiSelectLimit(): number {
      const userDefinedUiSchemOptions = this.control?.uischema
        ?.options as ControlElementExtendedOptions;
      return userDefinedUiSchemOptions?.['multiselect-options']?.limit || this.defaultLimit;
    },
  },
  data() {
    return {
      defaultLimit: 3,
      refElement: null,
      selected: [],
    };
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaControl(useJsonFormsAnyOfControl(props), (target) =>
      target.selectedIndex === 0 ? undefined : target.value
    );
  },
  watch: {
    selected(newValue) {
      let values = newValue.map((value: { const: string }) => {
        return value.const;
      });
      this.handleChange(this.control.path, [...values]);
    },
  },
});

export default MultiSelectRenderer;
</script>
