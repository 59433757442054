<template>
  <div
    v-if="visible"
    :id="id"
    :class="[
      styles.control.root,
      { disabled: disabled },
      errors && fieldData !== undefined ? styles.control.error : null,
    ]"
  >
    <template v-if="showLabel !== false">
      <label :for="id + '-input'" :class="styles.control.label" v-html="computedLabel"></label>
      <DescriptionWrapper :description="description" v-if="description" />
    </template>
    <div :class="styles.control.wrapper">
      <slot></slot>
    </div>

    <div :class="[errors && fieldData ? styles.control.error : null, 'error-description']">
      {{ errors ? errors : null }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, PropType } from 'vue';
import { Styles, Options } from '@jsonforms/vue-vanilla';
import DescriptionWrapper from '@/components/forms/DescriptionWrapper.vue';

const showLabel = inject<boolean>('showLabel', true);
const props = defineProps({
  id: {
    required: true,
    type: String,
  },
  fieldData: {
    required: false as const,
    type: [String, Number, Array, Object],
    default: undefined,
  },
  description: {
    required: false as const,
    type: String,
    default: undefined,
  },
  disabled: {
    required: false as const,
    type: Boolean,
    default: false,
  },
  errors: {
    required: false as const,
    type: String,
    default: undefined,
  },
  label: {
    required: false as const,
    type: String,
    default: undefined,
  },
  appliedOptions: {
    required: false as const,
    type: Object as PropType<Options>,
    default: undefined,
  },
  visible: {
    required: false as const,
    type: Boolean,
    default: true,
  },
  required: {
    required: false as const,
    type: Boolean,
    default: false,
  },
  isFocused: {
    required: false as const,
    type: Boolean,
    default: false,
  },
  styles: {
    required: true,
    type: Object as PropType<Styles>,
  },
});

const computedLabel = computed(
  () => `${props.label}${props.required ? ' <span class="required">*</span>' : ''}`
);
</script>
