<template>
  <span>
    <slot></slot>
  </span>
  <button @click="emit('click')" v-bind="$attrs">
    <font-awesome-icon icon="fa-solid fa-plus-large" />
  </button>
</template>
<script setup lang="ts">
interface Props {
  readonly classes?: string[];
}
defineProps<Props>();
const emit = defineEmits(['click']);
</script>
