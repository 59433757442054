<template>
  <div
    v-if="visible"
    :id="id"
    :class="[
      styles.control.root,
      { disabled: disabled },
      errors && fieldData ? styles.control.error : null,
    ]"
    class="inline-wrapper"
  >
    <label :for="id + '-input'" :class="styles.control.label">
      <slot></slot>
      {{ label }}<span v-if="required" class="required"> *</span>

      <DescriptionWrapper :description="description" v-if="description" />
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Styles, Options } from '@jsonforms/vue-vanilla';
import DescriptionWrapper from '@/components/forms/DescriptionWrapper.vue';

const InlineControlWrapper = defineComponent({
  name: 'InlineControlWrapper',
  components: {
    DescriptionWrapper,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    fieldData: {
      required: false as const,
      type: String,
      default: undefined,
    },
    description: {
      required: false as const,
      type: String,
      default: undefined,
    },
    disabled: {
      required: false as const,
      type: Boolean,
      default: false,
    },
    errors: {
      required: false as const,
      type: String,
      default: undefined,
    },
    label: {
      required: false as const,
      type: String,
      default: undefined,
    },
    appliedOptions: {
      required: false as const,
      type: Object as PropType<Options>,
      default: undefined,
    },
    visible: {
      required: false as const,
      type: Boolean,
      default: true,
    },
    required: {
      required: false as const,
      type: Boolean,
      default: false,
    },
    isFocused: {
      required: false as const,
      type: Boolean,
      default: false,
    },
    styles: {
      required: true,
      type: Object as PropType<Styles>,
    },
  },
  data() {
    return {
      showDescription: false,
    };
  },
  methods: {
    toggleDescription(): void {
      this.showDescription = !this.showDescription;
    },
  },
});

export default InlineControlWrapper;
</script>
