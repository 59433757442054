import {
  Markdown,
  InputTextField,
  PasswordField,
  CheckboxField,
  ArrayList,
  SelectItem,
  Textarea,
  NumberField,
  IntegerField,
  StringToken,
  MultiSelect,
  ArrayListBasic,
} from '@/renderers/renderer-definitions';

import { vanillaRenderers } from '@jsonforms/vue-vanilla';

export const JsonFormsRenderers = Object.freeze([
  ...vanillaRenderers,
  Markdown,
  InputTextField,
  PasswordField,
  CheckboxField,
  ArrayList,
  ArrayListBasic,
  SelectItem,
  MultiSelect,
  Textarea,
  NumberField,
  IntegerField,
  StringToken,
]);

/*
 * If we want to the default styles, we need to import defaultStyles from vue-vanilla and
 * use mergeStyles to combine them like:
 * mergeStyles(defaultStyles, {...});
 * We use our own custom classes. - Richard 20230713
 */

export const JsonFormsStyles = {
  control: {
    root: 'control',
    wrapper: 'wrapper',
    label: 'form-label',
    description: 'description',
    input: 'form-control',
    error: 'has-error',
    textarea: 'form-control',
    select: 'form-select',
    option: 'option',
  },
  verticalLayout: {
    root: 'v-layout',
    item: 'v-item',
  },
  horizontalLayout: {
    root: 'h-layout',
    item: 'h-item',
  },
  group: {
    root: 'group',
    label: 'group-label',
    item: 'group-item',
  },
  arrayList: {
    root: 'array-list',
    legend: 'array-list-legend',
    addButton: 'array-list-add',
    label: 'array-list-label',
    itemWrapper: 'array-list-item-wrapper',
    noData: 'array-list-no-data',
    item: 'array-list-item',
    itemToolbar: 'array-list-item-toolbar',
    itemLabel: 'array-list-item-label',
    itemContent: 'array-list-item-content',
    itemExpanded: 'expanded',
    itemMoveUp: 'array-list-item-move-up',
    itemMoveDown: 'array-list-item-move-down',
    itemDelete: 'array-list-item-delete',
  },
  label: {
    root: 'label-element',
  },
};
